import React, { useEffect, useState } from "react";
import ProductRate from "../components/ProductRate";
import StarRatings from "react-star-ratings";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
function RatingPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const table = searchParams.get("table_id");
  const [products, setProducts] = useState([]);
  const [orderId, setOrderId] = useState();
  const [feedback, setFeedback] = useState("");
  const [serviceRate, setServiceRate] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.foody.gomaplus.tech/api/cart/showToRate/1/${table}`
        );
        setProducts(response.data.data.products);
        setOrderId(response.data.data.bill_id);
      } catch (error) {
        console.error("Error fetching data from the API:", error);
      }
    };

    fetchData();
  }, []);
  const handleSubmit = async () => {
    try {
      await axios.post(
        `https://api.foody.gomaplus.tech/api/rating/service/add/${orderId}`,
        {
          feedback: feedback,
          serviceRate: serviceRate,
        }
      );
      toast.success("Successfully Rated");
    } catch (error) {
      console.error("Error posting data to the API:", error);
    }
    setFeedback("");
    setServiceRate(0);
    window.location.reload()
  };
  return (
    <div className=" bg-white mx-auto  max-w-3xl space-y-4 min-h-screen py-5 px-6 ">
      <Toaster />
      <img src={logo} alt="" className=" w-40 mx-auto py-2" />
      {/* <h1 className="text-2xl tracking-widest font-semibold text-[#C4C4C4] pb-10">
        We appreciate your feedback
      </h1> */}
      <div className=" text-start py-5 ">
        <div className="flex justify-between items-center">
          <h1 className="text-[#9c9c9c] font-medium text-base">Service Rate</h1>
          <h1 className="text-[#9c9c9c] font-medium text-base">تقييم الخدمة</h1>
        </div>

        <div className="flex items-center justify-center">
          <StarRatings
            rating={serviceRate} // Use the state variable here
            changeRating={(newRating) => setServiceRate(newRating)} // Update the state on rating change
            numberOfStars={5}
            starDimension="30px"
            starSpacing="1px"
            starRatedColor="#FFC700"
            starHoverColor="#FFC700"
          />
        </div>
      </div>
      <div className=" text-start space-y-4 py-5 ">
        <div className="flex justify-between items-center">
          {" "}
          <h1 className="text-[#9c9c9c] font-medium text-base">Meals Rate</h1>
          <h1 className="text-[#9c9c9c] font-medium text-base">
            تقييم الوجبات
          </h1>
        </div>
        <div className="border-2 rounded-lg max-w-xl sm:px-14  mx-auto py-4 ">
          {products?.map((product) => {
            return <ProductRate product={product} key={product.id} />;
          })}
        </div>
      </div>
      <div className=" text-start  ">
        <div className="flex justify-between items-center">
          <h1 className="text-[#9c9c9c] font-medium text-base">
            Tell us about your experience
          </h1>
          <h1 className="text-[#9c9c9c] font-medium text-base">
            أخبرنا عن تجرتبك
          </h1>
        </div>
        <div className="py-4 px-4">
          <div className="mb-6">
            <input
              placeholder="Add note"
              type="text"
              id="large-input"
              className="flex w-full p-6 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className=" w-full  text-clip  shadow-inner  max-w-4xl items-center border-2 rounded-lg flex gap-10  px-6 mx-auto py-6 my-6">
        <button
          className="bg-gradient-to-br from-[#d22afc] via-[#a758ed] to-[#7073de] rounded-lg px-1 py-2 w-full text-white font-medium text-lg"
          onClick={handleSubmit} // Call handleSubmit when the button is clicked
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default RatingPage;
